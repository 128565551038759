<template>
  <footer class="p-4 text-center">
    © {{ new Date().getFullYear() }}
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style>
</style>