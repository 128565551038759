<template>
  <aside
    class="left-sidebar"
    :class="
      Sidebar_drawer ? `show-sidebar bg-${SidebarColor}` : `bg-${SidebarColor}`
    "
  >
    <div :class="`bg-${logoColor} navbar-header`">
      <LogoDark />
      <span
        class="d-block d-lg-none close-sidebar-btn"
        @click="showMobileSidebar"
        ><i class="mdi mdi-close font-18"></i
      ></span>
    </div>

    <div class="scrlbar">
      <ul class="nav flex-column mb-0">
        <template v-for="(route, i) in routes">
          <!---Single Menu -->
          <li class="nav-item" :key="i">
            <template v-if="route.children">
              <div v-b-toggle="'collapse-' + i" class="nav-link d-flex">
                <i :class="route.icon"></i>
                <span class="hide-text">{{ route.title }}</span>
              </div>
            </template>
            <template v-else>
              <router-link v-b-toggle="'collapse-' + i" :to="`${route.to}`" class="nav-link d-flex">
                <i :class="route.icon"></i>
                <span class="hide-text">{{ route.title }}</span>
              </router-link>
            </template>
            <b-collapse :id="'collapse-' + i" class="mt-2" v-if="route.children">
                <ul class="nav flex-column mb-0" v-show="true">
                  <li class="nav-item ml-4" v-for="(link, j) in route.children" :key="j" :title="link.title">
                    <router-link :to="`${link.to}`" class="nav-link d-flex">
                      <span class="hide-text">{{ link.title }}</span>
                    </router-link>
                  </li>
                </ul>
            </b-collapse>
          </li>
        </template>
      </ul>
    </div>
  </aside>
</template>

<script>
import LogoDark from "../logo-dark/LogoDark";
import { mapState } from "vuex";
import SidebarData from "./SidebarData";

export default {
  name: "VerticalSidebar",
  components: {
    LogoDark,
  },
  data: () => ({
    initialShow: true,
    initRoutes: SidebarData
  }),
  computed: {
    ...mapState(["logoColor", "Sidebar_drawer", "SidebarColor"]),
    ...mapState({
      permissions: state => state.Permissions.items,
    }),
    check() {
      return console.log(this.$router.history.current);
    },
    routes() {
      if (!this.initRoutes || !this.permissions) {
        return [];
      }

      let permissions = this.permissions;

      console.log('this.permissions');
      console.log(this.permissions);

      let SidebarData = this.initRoutes.filter(section => {
          if (section.permission === undefined) {
              return true
          }

          return permissions.includes(section.permission)
      });

      return SidebarData.map(section => {
          if (!section.children) {
            return { ...section }
          }

          const children = section.children.filter(subSection => {
            if (subSection.permission === undefined) {
              return true
            }

            return permissions.includes(subSection.permission)
          });

          return { ...section, children }
      });
    }
  },
  methods: {
    showMobileSidebar() {
      this.$store.commit("SET_SIDEBAR_DRAWER", false);
    },
  },
};
</script>

<style>
</style>