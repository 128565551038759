export default [
  {
    id: "1",
    icon: "mdi mdi-view-dashboard",
    title: "Главная",
    to: "/dashboard"
  },
  {
    id: "2",
    icon: "mdi mdi-bell",
    title: "События",
    to: "/events",
    children: [
      {
        title: 'Подозрительные события',
        to: "/events/suspicious-events",
      }
    ]
  },
  {
    id: "3",
    icon: "mdi mdi-account-box",
    title: "Заказы и брони",
    to: "/orders",
    permission: 'order',
    children: [
      {
        title: 'Работа с заказами',
        to: "/orders/work",
      },
      {
        title: 'Мои заказы',
        to: "/orders/bookings",
      }
    ]
  },
  {
    id: "4",
    icon: "mdi mdi-bus",
    title: "Работа с рейсами",
    to: "/flights",
    children: [
      {
        title: 'График работы транспортных средств',
        to: "/flights/vehicle-schedule",
      },
      {
        title: 'Управление рейсами и работа с посадочными ведомостями',
        to: "/flights/trips",
      },
      {
        title: 'Управление регулярными расписаниями',
        to: "/flights/periodic-trips",
      },
/*      {
        title: 'Групповое управление рейсами',
        to: "/flights/trips-group-managing",
      }*/
    ]
  },
  {
    id: "5",
    icon: "mdi mdi-archive",
    title: "Справочники",
    to: "/dictionaries",
    children: [
      {
        title: 'Цены',
        to: "/dictionaries/prices",
      },
      {
        title: 'Водители и пропуска физ.лиц',
        to: "/dictionaries/drivers",
      },
      {
        title: 'Схемы ТС',
        to: "/dictionaries/vehicle-schemas",
      },
      {
        title: 'Транспортные средства',
        to: "/dictionaries/vehicles",
      },
      {
        title: 'Перевозчики',
        to: "/dictionaries/carriers",
      },
      {
        title: 'Маршруты',
        to: "/dictionaries/routes",
      },
      {
        title: 'Пользователи',
        to: "/dictionaries/users",
      }
    ]
  },
/*  {
    id: "6",
    icon: "mdi mdi-camcorder",
    title: "Диспетчеризация",
    to: "/dispatching",
    children: [
      {
        title: 'КПП и управление отправлениями',
        to: "/dispatching/departures",
      },
      {
        title: 'Отправление водителем',
        to: "/dispatching/driver-departing",
      }
    ]
  },*/
  {
    id: "7",
    icon: "mdi mdi-calculator",
    title: "Статистика и отчеты",
    to: "/statistics",
    children: [
      {
        title: 'Продажи и возвраты (по датам действия)',
        to: "/statistics/sales-and-refunds",
      },
      {
        title: 'Продажи и возвраты кассиров (по датам действия или посадочным ведомостям)',
        to: "/statistics/sales-and-refunds-by-cashiers",
      },
      {
        title: 'Детальная статистика по продажам и возвратам',
        to: "/statistics/detailed-statistics-by-sales-and-refunds",
      }
    ]
  },
  {
    id: "8",
    icon: "mdi mdi-information",
    title: "Оперативная информация",
    to: "/operative-information",
    children: [
      {
        title: 'Расписания и ТС',
        to: "/operative-information/schedules-and-vehicles",
      },
/*      {
        title: 'Запросы на доступ к КПП',
        to: "/operative-information/request-to-access",
      }*/
    ]
  },
  {
    id: "9",
    icon: "mdi mdi-earth",
    title: "Сайт",
    to: "/website",
    children: [
      {
        title: 'Новости',
        to: "/website/news",
        permission: 'news',
      },
      {
        title: 'Полезная информация',
        to: "/website/helpful-information",
        permission: 'helpful-information',
      },
      {
        title: 'Популярные направления',
        to: "/website/popular-directions",
        permission: 'popular-directions',
      },
      {
        title: 'Адреса',
        to: "/website/stations",
        permission: 'stations',
      },
      {
        title: 'Вопрос-ответ',
        to: "/website/faq",
        permission: 'faq',
      }
    ]
  }
];
