<template>
  <b-navbar-brand href="#">
       <!-- Logo icon -->
        <b class="logo-icon">
            <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
            <!-- Light Logo icon -->
<!--            <img src="../../../assets/images/logo-r.png" width="150" alt="homepage" >-->
        </b>
  </b-navbar-brand>
</template>

<script>
export default {
    name: 'LogoDark',
}
</script>
